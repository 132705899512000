import { render, staticRenderFns } from "./InfoBox.vue?vue&type=template&id=60a8810a&scoped=true&"
import script from "./InfoBox.vue?vue&type=script&lang=js&"
export * from "./InfoBox.vue?vue&type=script&lang=js&"
import style0 from "./InfoBox.vue?vue&type=style&index=0&id=60a8810a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a8810a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VAlert,VCard})
