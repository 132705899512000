<template>
  <v-stepper v-if="canShowProgressBar" class="progress-bar" :value="step" :alt-labels="true">
    <v-stepper-header>
      <v-stepper-step
        step="1"
        :complete="step > 1"
        complete-icon="mdi-check"
        :editable="step > 1"
        edit-icon="mdi-check"
        error-icon="mdi-alert-octagon-outline"
        :rules="[() => firstStepClear]"
        @click="goToQuestionnaire()"
      >
        {{ $t('aml.questionnaire') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        step="2"
        :complete="step > 2"
        complete-icon="mdi-check"
        :editable="step > 2"
        edit-icon="mdi-check"
        error-icon="mdi-alert-octagon-outline"
        :rules="[() => secondStepClear]"
        @click="goToIdentity()"
      >
        {{ $t('aml.identity') }}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3">
        {{ $t('aml.microtransaction') }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AuthsProgressBar',
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isWithoutDocumentVerification: 'authsDocument/isWithoutDocumentVerification',
      isWithoutPoliticalVerification: 'authsPolitical/isWithoutPoliticalVerification',
      isWithoutMicroTransactionVerification:
        'authsMicroTransaction/isWithoutMicroTransactionVerification',
      isPoliticalDone: 'authsPolitical/isPoliticalDone',
      isDocumentDone: 'authsDocument/isDocumentDone',
    }),
    canShowProgressBar() {
      return !(
        this.isWithoutPoliticalVerification ||
        this.isWithoutDocumentVerification ||
        this.isWithoutMicroTransactionVerification
      );
    },
    firstStepClear() {
      return this.step <= 1 || true === this.isPoliticalDone;
    },
    secondStepClear() {
      return this.step <= 2 || true === this.isDocumentDone;
    },
  },
  methods: {
    ...mapActions({
      setModeEditing: 'auths/setModeEditing',
    }),
    goToQuestionnaire() {
      if ('authentication-questionnaire' === this.$route.name) {
        return;
      }

      this.setModeEditing(true);
      this.$router.push({
        name: 'authentication-questionnaire',
        params: {
          authToken: this.$route.params.authToken,
        },
      });
    },
    goToIdentity() {
      if ('authentication-identity' === this.$route.name || this.step < 2) {
        return;
      }

      this.setModeEditing(true);
      this.$router.push({
        name: 'authentication-identity',
        params: {
          authToken: this.$route.params.authToken,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  margin: 0 auto 2rem;
  max-width: 30rem;

  @include display(960px) {
    max-width: none;
  }
}
</style>
