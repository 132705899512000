<template>
  <Loader v-if="isLoading" />

  <v-card
    v-else
    :color="workspaceLayerColor"
    class="mx-auto my-md-10 pt-10 text-center"
    width="100%"
    max-width="45rem"
  >
    <AuthsProgressBar :step="1" />

    <v-card-text class="px-10 px-md-15">
      <h1 class="headline font-weight-black secondary--text mb-2">
        {{ $t('aml.questionnaire') }}
      </h1>
    </v-card-text>

    <InfoBox :message="authsPoliticalText" />

    <v-card-text class="px-5 px-md-15">
      <v-row class="mt-0 mt-md-5 questionnaire-check" align="center" justify="center">
        <v-col cols="auto">
          <v-checkbox
            v-model="isNotPolitical"
            :label="$t('aml.questionnaire.not_political')"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="px-10 mt-5 mb-15">
      <v-btn
        class="mt-5 mx-auto ml-md-auto mr-md-0"
        color="primary"
        min-width="15rem"
        :loading="isSubmitted"
        x-large
        @click="submit()"
      >
        {{ $t('general.continue') }}
      </v-btn>
    </v-card-actions>

    <AlertMessage v-model="error" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { i18n } from '@/plugins/i18n';
import AuthsProgressBar from '@verification/components/AuthsProgressBar';
import AlertMessage from '@verification/components/AlertMessage';
import InfoBox from '@verification/components/InfoBox';
import Loader from '@/components/Loader';
import { authPoliticalSeen } from '@verification/services/logService';

export default {
  name: 'Questionnaire',
  components: {
    AuthsProgressBar,
    AlertMessage,
    InfoBox,
    Loader,
  },
  data() {
    return {
      error: false,
      isNotPolitical: false,
      submitted: false,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      isWithoutPoliticalVerification: 'authsPolitical/isWithoutPoliticalVerification',
      political: 'authsPolitical/political',
      workspace: 'auths/workspace',
      workspaceLayerColor: 'auths/workspaceLayerColor',
    }),
    authsPoliticalText() {
      const currentLocale = i18n.locale;

      return this.workspace?.aml_text[currentLocale] || this.$t('aml.questionnaire.form_info');
    },
    isPolitical: {
      get() {
        return !this.isNotPolitical;
      },
      set(value) {
        this.isNotPolitical = !value;
      },
    },
    isLoading() {
      return !this.loaded;
    },
    isSubmitted() {
      return this.submitted;
    },
  },
  beforeMount() {
    if (this.isWithoutPoliticalVerification) {
      return this.$router.push({
        name: 'authentication-liveliness',
        params: {
          authToken: this.$route.params.authToken,
        },
      });
    }
  },
  mounted() {
    if (this.isWithoutPoliticalVerification) {
      return;
    }

    this.fetchAuths(this.$route.params.authToken)
      .then(() => {
        authPoliticalSeen({ authToken: this.$route.params.authToken });

        if (this.political.is_political !== null) {
          this.isNotPolitical = !this.political.is_political;
        }

        this.loaded = true;
      })
      .catch(() => {
        this.loaded = true;
        this.error = true;
      });
  },
  methods: {
    ...mapActions({
      fetchAuths: 'auths/fetchAuths',
      setModeEditing: 'auths/setModeEditing',
      submitPolitical: 'authsPolitical/submitPolitical',
    }),
    submit() {
      const authToken = this.$route.params.authToken;
      const data = {
        is_political: this.isPolitical,
      };

      this.submitted = true;
      this.setModeEditing(false);
      this.submitPolitical({ authToken, data })
        .then(() => {
          return this.$router.push({
            name: 'authentication-liveliness',
            params: { authToken },
          });
        })
        .catch(() => {
          this.submitted = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire-check {
  border-top: 1px solid #dee7f4;
  border-bottom: 1px solid #dee7f4;
}
</style>
