import zxcvbn from 'zxcvbn';

/**
 * Method to compute password score
 *
 * @param password
 * @returns {{color: string, value: number}}
 */
export const computePasswordScore = ({ password }) => {
  const result = zxcvbn(password);

  switch (result.score) {
    case 4:
      return {
        color: 'success',
        value: 100,
      };
    case 3:
      return {
        color: 'success',
        value: 75,
      };
    case 2:
      return {
        color: 'warning',
        value: 50,
      };
    case 1:
      return {
        color: 'error',
        value: 25,
      };
    default:
      return {
        color: 'error',
        value: 0,
      };
  }
};

/**
 * Method to escape html characters in string
 *
 * @param inputText
 * @returns {*}
 */
export const escapeHtmlContent = ({ htmlContent }) => {
  if (!htmlContent) {
    return htmlContent;
  }

  return htmlContent
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};
