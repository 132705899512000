import { apiTokenBasedRequest } from '@/services/ApiService';

export const authPoliticalSeen = ({ authToken }) => {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/political/see', authToken);
};

export const authDocumentSeen = ({ authToken }) => {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/document/see', authToken);
};

export const authSmsSeen = ({ authToken }) => {
  return apiTokenBasedRequest('PATCH', 'api/v1/auth/sms/see', authToken);
};

export const authMicroTransactionSeen = ({ authToken }) => {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/microTransaction/see', authToken);
};

export const authBankIdSeen = ({ authToken }) => {
  return apiTokenBasedRequest('PATCH', '/api/v1/auth/bankId/see', authToken);
};
