<template>
  <v-card class="mx-5 mx-md-12 info-box" elevation="0">
    <v-alert type="warning" icon="mdi-information-outline" border="left" color="#9D6F42" outlined>
      <span v-html="currentMessage"></span>
    </v-alert>
  </v-card>
</template>

<script>
import { escapeHtmlContent } from '@/common/reusable/security';

export default {
  name: 'InfoBox',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentMessage() {
      return escapeHtmlContent({ htmlContent: this.message }).replace(/\n/g, '<br />');
    },
  },
};
</script>

<style lang="scss" scoped>
.info-box {
  .v-alert {
    background-color: #fffad1 !important;
    border: unset !important;
    font-weight: bolder;
    text-align: left;
  }
}
</style>
